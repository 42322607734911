<!-- 商品统计 -->

<template>
  <div class="productStatistics">
    <div class="globle_border" v-loading="loading">
      <!-- echart -->
      <el-date-picker v-model="topMonthValue" type="monthrange" range-separator="至" start-placeholder="开始月份"
        end-placeholder="结束月份" value-format="yyyyMM" @change="handleSelectMonthTop" :clearable="false"
        :picker-options="monthpickoption"></el-date-picker>
      <div class="echart">
        <div v-show="shopSale.length > 0" class="echarts">
          <div class="echart" id="main" style="width: 1600px; height: 400px"></div>
        </div>
        <el-empty v-show="shopSale.length == 0" description="暂无图表数据"></el-empty>
      </div>
      <!-- 顶部搜索 -->
      <div class="search">
        <el-input v-model="nameValue" placeholder="请搜索商品名称" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-input v-model="codeValue" placeholder="请搜索商品条形码" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-date-picker v-model="monthValue" type="month" placeholder="请选择" value-format="yyyyMM"
          @change="handleSelect"></el-date-picker>
      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="排名" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column label="图片" width="110">
            <template slot-scope="scope">
              <span class="show_img" @click="previewImage(scope.row.picUrl)">预览图片</span>
            </template>
          </el-table-column>
          <!-- <el-table-column min-width="120" show-overflow-tooltip prop="serialId" label="商品编号"></el-table-column> -->
          <el-table-column min-width="120" show-overflow-tooltip prop="barCode" label="商品条形码"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="brand" label="品牌"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="productName" label="商品名称"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="categoryName" label="分类名称"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="specs" label="规格"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="statPeriod" label="统计日期">
            <template slot-scope="scope">
              <span>{{ formatStatPeriod(scope.row.statPeriod) }}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="count" label="销售数量(件)"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="sellAmount" label="销售金额(元)"></el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>

    <el-image-viewer v-if="showViewer" :on-close="() => {
      showViewer = false
    }
      " :url-list="imgList"></el-image-viewer>
  </div>
</template>

<script>
import * as echarts from "echarts"
export default {
  components: {
    "el-image-viewer": () => import("element-ui/packages/image/src/image-viewer"),
  },
  data() {
    // 获取当前日期
    const currentDate = new Date()

    // 获取当前年月
    const currentYear = currentDate.getFullYear()
    const currentMonth = currentDate.getMonth() + 1 // 月份是从 0 开始计数的，所以要加 1

    // 计算 startTime
    const elevenMonthsAgo = currentMonth - 11 // 往前推 11 个月
    const startYear = elevenMonthsAgo > 0 ? currentYear : currentYear - 1
    const startMonth = elevenMonthsAgo > 0 ? elevenMonthsAgo : 12 + elevenMonthsAgo
    const startTime = `${startYear}${startMonth < 10 ? "0" + startMonth : startMonth}`

    // 设置 endTime
    const endTime = `${currentYear}${currentMonth < 10 ? "0" + currentMonth : currentMonth}`
    return {
      loading: false,
      nameValue: "", // 搜索商品名称
      codeValue: "", // 搜索商品条形码
      monthValue: "", // 搜索月份
      // 表格数据
      tableData: [],
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      // 图片预览
      showViewer: false,
      imgList: [],

      // echarts
      topMonthValue: [startTime, endTime],
      shopSale: [],
      myChart: null,
      startTime: startTime,
      endTime: endTime,

      // 时间限制
      trendData: {
        date: [],
      },
      minDate: null,
      maxDate: null,
      monthpickoption: {
        disabledDate: time => {
          if (this.minDate !== null && this.maxDate === null) {
            let minMonth = this.minDate.getMonth(),
              lastYear = new Date(this.minDate).setMonth(minMonth - 11),
              nextYear = new Date(this.minDate).setMonth(minMonth + 11)
            let minTime = this.minDate.getTime()
            let nextTime = new Date().setMonth(new Date().getMonth() + 11)
            let lastTime = new Date().setMonth(new Date().getMonth() - 11)
            if (minTime <= nextTime || minTime >= lastTime) {
              //开始日期少于当前月+12个月 并且大于当前月-12个月，则表示只限制前面的范围
              return time.valueOf() > new Date().getTime() || time.valueOf() > nextYear.valueOf() || time.valueOf() < lastYear.valueOf()
            } else {
              // 只能选 minDate 前后一年的范围
              return time.valueOf() < lastYear.valueOf() || time.valueOf() > nextYear.valueOf()
            }
          } else {
            let startMonth = this.trendData.date[0]
            let month = new Date(startMonth).getMonth()
            let lastMonth = new Date(startMonth).setMonth(month - 11)
            //如果有默认值，只禁用开始日期，因为已经限定了禁用未来范围，且默认值已经为12个月范围
            return this.monthPick(time) || time < lastMonth.valueOf()
          }
        },
        onPick: ({ minDate, maxDate }) => {
          this.minDate = minDate
          this.maxDate = maxDate
        },
      },
    }
  },
  created() {
    this.getProductTotalPageList()
  },
  mounted() {
    this.initEcharts()
    this.setEchartsOption()
  },
  methods: {
    // 获取商品列表
    getProductTotalPageList() {
      this.loading = true
      this.$axios
        .get(this.$api.getProductTotalPageList, {
          params: {
            statType: 2, // 统计类型
            statPeriod: this.monthValue || null, // 统计时期
            barCode: this.codeValue || null, // 商品条形码
            productName: this.nameValue || null, // 商品名称
            // barCode: this.barValue || null, // 商品条形码
            page: this.currentPage,
            pageSize: this.pageSize,
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list
            this.totalItemsCount = res.data.result.totalCount
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    monthPick(time) {
      // 获取当前的月份信息
      const date = new Date()
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      if (month >= 1 && month <= 9) {
        month = "0" + month
      }
      const nowDate = year.toString() + month.toString()
      // 获取时间选择器的月份信息
      const timeyear = time.getFullYear()
      let timemonth = time.getMonth() + 1
      if (timemonth >= 1 && timemonth <= 9) {
        timemonth = "0" + timemonth
      }
      const elTimeData = timeyear.toString() + timemonth.toString()
      return elTimeData > nowDate
    },
    handleSelectMonthTop(value) {
      this.topMonthValue = value
      this.setEchartsOption()
    },
    // 初始化echarts
    initEcharts() {
      var chartDom = document.getElementById("main")
      this.myChart = echarts.init(chartDom)
    },
    setEchartsOption() {
      let params = {}
      // 时间区间（开始时间-结束时间）
      if (this.topMonthValue && this.topMonthValue.length === 2) {
        params.startTime = this.topMonthValue[0]
        params.endTime = this.topMonthValue[1]
      } else {
        params.startTime = null
        params.endTime = null
      }
      this.$axios.get(this.$api.getProductTotalStatList, { params }).then(res => {
        if (res.data.code == 100) {
          this.shopSale = res.data.result
          // 侧边
          let legendData = []
          legendData = res.data.result.map(item => item.productName)
          // 处理原始数据，提取所需信息
          const processedData = res.data.result.map(item => {
            return {
              name: item.productName,
              data: item.totalStatVoList.map(sale => sale.sellAmount),
              statPeriod: item.totalStatVoList.map(sale => sale.statPeriod),
            }
          })
          // 构建 X轴
          const XData = processedData[0]?.statPeriod
          // 构建 series 数组
          const series = processedData.map(item => {
            return {
              name: item.name,
              type: "line",
              smooth: true,
              data: item.data,
            }
          })
          var option
          option = {
            tooltip: {
              trigger: "axis",
            },
            color: ["#f76848", "#74bdcf", "#7db7f7", "#e7bcf3", "#ffa500", "#4dd0e1", "#ff7f50", "#c0c0c0", "#800080", "#ffd700", "#228b22", "#ff4500"],
            legend: {
              data: legendData,
              type: "scroll",
              right: 0,
              top: 0,
              orient: "vertical",
              itemGap: 15,
              formatter: function (name) {
                var maxLengthPerLine = 14
                var result = ""
                for (var i = 0; i < name.length; i++) {
                  result += name[i]
                  if ((i + 1) % maxLengthPerLine === 0 && i !== name.length - 1) {
                    result += "\n"
                  }
                }
                return result
              },
            },
            grid: {
              left: "3%",
              right: "14%",
              bottom: "3%",
              containLabel: true,
            },
            xAxis: {
              name: "年月",
              type: "category",
              boundaryGap: false,
              data: XData,
            },
            yAxis: {
              type: "value",
              name: "金额（元）",
            },
            series: series,
          }
          option && this.myChart.setOption(option)
        }
      })
    },
    formatStatPeriod(statPeriod) {
      statPeriod = statPeriod?.toString()
      if (statPeriod?.length === 6) {
        // 如果长度是6，则假设是年份和月份，格式为YYYYMM
        const year = statPeriod.substring(0, 4)
        const month = statPeriod.substring(4, 6)
        return `${year}-${month}`
      } else if (statPeriod?.length === 8) {
        // 如果长度是8，则假设包含年份、月份和日期，格式为YYYYMMDD
        const year = statPeriod.substring(0, 4)
        const month = statPeriod.substring(4, 6)
        const day = statPeriod.substring(6, 8)
        return `${year}-${month}-${day}`
      } else {
        // 如果长度不是6也不是8，返回原始值
        return statPeriod
      }
    },
    // 清空
    clearData() {
      this.tableData = []
      this.currentPage = 1
    },
    // 时间搜索
    handleSelect(value) {
      this.clearData()
      this.monthValue = value
      this.getProductTotalPageList()
    },
    // 搜索
    onSearch() {
      this.clearData()
      this.getProductTotalPageList()
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.pageSize = e
      this.onSearch()
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = []
      this.currentPage = e
      this.getProductTotalPageList()
    },
    // 图片预览
    previewImage(url) {
      this.imgList[0] = url
      this.showViewer = true
    },
  },
}
</script>

<style scoped lang="scss">
.productStatistics {

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 250px;
    }

    .el-input,
    .el-select,
    .el-button {
      margin-left: 20px;
    }
  }

  .show_img {
    color: #069bbc;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  ::v-deep .el-date-editor {
    background-color: #f2f2f2;
    border: none;
    border-radius: 10px;
    margin-left: 20px;
  }

  ::v-deep .el-range-input {
    background-color: #f2f2f2;
  }
}
</style>
